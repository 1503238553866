.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  inset: 0;
}

.content {
  background-color: rgb(244 244 245);
  display: flex;
  flex-direction: column;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 68%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.rootContainer {
  /* margin-bottom: 2rem; */
  padding: 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  flex: 1 1 0%;
  background-color: #ffffff;
}

.drag {
  background-color: rgb(212 212 216);
  width: 3rem;
  height: 0.375rem;
  flex-shrink: 0;
  border-radius: 9999px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.secondContainer {
  max-width: 28rem;
}
