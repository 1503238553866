.displayImage {
  width: 100%;
  height: 100%;
  /* position: relative; */
}

.card {
  /* position: absolute;
  bottom: 0;
  height: 100vh; */
  overscroll-behavior: contain;
  -webkit-box-shadow: -0px -10px 20px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -20px 20px 2px rgba(0, 0, 0, 0.25);
  width: 100vw;
  transform: translateY(-2%);
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
